import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { outlinedInputClasses } from '@mui/material';
import { styled } from '@mui/system';
import Select from '@mui/material/Select';

const StyledSelect = styled( Select )( `
  & .${ outlinedInputClasses.notchedOutline } {
    border-color: #6b7280;
  }
  &:hover .${ outlinedInputClasses.notchedOutline } {
    border-color: #263250;
  }
  &.${ outlinedInputClasses.focused } .${ outlinedInputClasses.notchedOutline } {
    border-color: #263250;
  }
`);

const MobileSelect = ( { label, value, onChange, disabled = false, children, required = true } ) =>
{

    return (
        <>
            <FormControl fullWidth>
                <InputLabel
                    sx={ {
                        "&.Mui-focused": {
                            color: "black"
                        }
                    } }
                    id="demo-simple-select-label">{ label }</InputLabel>
                <StyledSelect
                    MenuProps={ {
                        PaperProps: {
                            sx: {
                                "& .MuiPaper-root": {
                                    width: "100%"
                                },
                                "& .MuiMenuItem-root.Mui-selected": {
                                    backgroundColor: "#263250",
                                    color: "white",

                                },
                                "& .MuiMenuItem-root:hover": {
                                    backgroundColor: "#263250",
                                    color: "white"
                                },
                                "& .MuiMenuItem-root.Mui-selected:hover": {
                                    backgroundColor: "#263250",
                                    color: "white"
                                }
                            }
                        }
                    } }
                    required={ required }
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={ label }
                    value={ value }
                    onChange={ onChange }
                    disabled={ disabled }
                >
                    { children }
                </StyledSelect>
            </FormControl>
        </>
    );
};

export default MobileSelect;