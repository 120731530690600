import Link from "next/link";
import React from "react";
import { agreeToTerms } from "../utils/envConfig";

export default function AgreeToTerms({
    agreed = true,
    className,
    onClick = (e) => {},
    inputProps,
}) {
    return (
        <div
            className={`text-sm flex gap-1 items-center cursor-pointer font-Lexend text-navy ${className}`}
            onClick={onClick}
        >
            <input
                type="checkbox"
                name="agree"
                id="agree"
                checked={agreed}
                readOnly
                width={30}
                height={30}
                {...inputProps}
                className={`cursor-pointer border focus:ring-0 outline-none text-black ${inputProps?.className}`}
            />
            <span>
                By submitting, I agree to the{" "}
                <Link href="/privacy-policy">
                    <a
                        className="underline"
                        onClick={(e) => e.stopPropagation()}
                    >
                        Privacy Policy
                    </a>
                </Link>{" "}
                and{" "}
                <Link href="/terms-and-conditions">
                    <a
                        className="underline"
                        onClick={(e) => e.stopPropagation()}
                    >
                        Terms & Conditions
                    </a>
                </Link>
            </span>
        </div>
    );
}
