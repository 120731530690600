import React, { useState } from "react";
import { emailRegex, validateEmail } from "../../utils/envConfig";

export default function InputEmail ( {
    inputProps = { onBlur: ( e ) => { }, onChange: ( e ) => { } },
    onInputChange = ( text = "" ) => { },
    onError = ( err = "" ) => { },
    containerClassNames = "",
    children
} )
{
    return (
        <>
            <div className={ containerClassNames }>
                <input
                    type="email"
                    name="email"
                    placeholder="[A-Z0-9.-]+@[A-Z0-9.-]+[A-Z]{2,4}"
                    { ...inputProps }
                    required={ inputProps?.required }
                    className={ `w-full appearance-none  py-1 px-2  focus:ring-0  !rounded-full  border  ${ inputProps?.className }` }
                    onChange={ ( e ) =>
                    {
                        onInputChange( e.target.value );

                        if ( inputProps.onChange )
                        {
                            inputProps?.onChange( e );
                        }
                    } }
                    onFocus={ ( e ) =>
                    {
                        onError( "" );

                        if ( inputProps?.onFocus )
                        {
                            inputProps.onFocus( e );
                        }
                    } }
                    onBlur={ ( e ) =>
                    {
                        const { name, value } = e.target;

                        const isValidEmail = validateEmail( value );

                        if ( inputProps?.required )
                        {
                            !value?.trim().length
                                ? onError( "Email is required" )
                                : onError(
                                    isValidEmail
                                        ? ""
                                        : "Invalid email address"
                                );
                        }

                        if ( inputProps.onBlur )
                        {
                            inputProps?.onBlur( e );
                        }
                    } }
                />
                {
                    children
                }
            </div>
        </>
    );
}
