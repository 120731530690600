import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/system';
import TextField from '@mui/material/TextField';

const CssTextField = styled( TextField )( {
  '& label.Mui-focused': {
    color: '#CE3737',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#CE3737',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#6b7280',
    },
    '&:hover fieldset': {
      borderColor: '#6b7280',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#CE3737',
    },
    paddingBottom: '16px',
  },
} );

const MobileTextField = ( { label, value, onChange, required = false, type = "text", multiline = false, disabled = false } ) =>
{

  return (
    <>
      <FormControl fullWidth >
        <CssTextField
          required={ required }
          id="standard-name"
          label={ label }
          value={ value }
          type={ type }
          multiline={ multiline }
          onChange={ onChange }
          disabled={ disabled }
        >
        </CssTextField>
      </FormControl>
    </>
  );
};

export default MobileTextField;