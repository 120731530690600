import React, { useState, useEffect } from "react";
import MobileSelect from "./MobileSelect";
import { toast } from "react-toastify";
import MenuItem from "@mui/material/MenuItem";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const StepTwo = ({
  nextStep,
  handleStep,
  handleFormData,
  values,
  setFormData,
  page=false
}) => {
  // State Variables

  // Constants
  // Validate User Token

  const headers = {
    "Content-Type": "application/json",
    // Authorization: `Bearer ${access_token}`,
  };

  const handleChoice = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      propertyMultiple: val,
    }));
    val ? handleStep(4) : handleStep(3);
  };

  return (
    <form>
      <div className="grid grid-cols-1 justify-center items-center">
        <p className="text-2xl font-bold text-center">I wish to add</p>
        <div className={classNames(page?"md:grid-cols-1":"md:grid-cols-2",
        "grid grid-cols-1 gap-y-8  gap-x-8 p-4 h-full lg:justify-center justify-between justify-items-between lg:items-center")}>
          <div
            onClick={() => handleChoice(false)}
            className="relative flex flex-col gap-y-2 pt-4 justify-between items-center h-full shadow-2xl rounded-md text-center text-xl overflow-hidden w-full  cursor-pointer"
          >
            <p className="font-bold text-xl pb-4">One property</p>
            {/* <button className="lg:text-xl text-sm flex bg-[#ffac2e] justify-center items-center  text-white font-bold cursor-pointer drop-shadow-2xl rounded-md px-4 py-2">
              <p className="truncate">Create listing</p>
            </button> */}
            <div className="">
              <img src="/img/single.jpg" className="h-36 align-bottom" />
            </div>
          </div>
          <div
            onClick={() => handleChoice(true)}
            className="relative flex flex-col gap-y-2 pt-4 justify-center items-center h-full shadow-2xl rounded-md text-center text-xl overflow-hidden w-full  cursor-pointer"
          >
            <p className="font-bold text-xl pb-4">Multiple Properties</p>
            {/* <button className="lg:text-xl text-sm flex bg-new-listing justify-center items-center  text-white font-bold cursor-pointer drop-shadow-2xl rounded-md px-4 py-2">
              <p className="truncate">Create listing</p>
            </button> */}
            <div className="">
              <img src="/img/multiple.jpg" className="h-40" />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="grid grid-cols-2 gap-x-0 items-start  px-4 mb-4">
        <div className="mt-1 col-span-2 justify-self-end">
          <button
            type="submit"
            id="kt_sign_in_submit"
            className="px-16 py-2.5 text-xl bg-red-500 rounded-full text-white my-3 button button--aylen  hover:bg-red-700  relative block  overflow-hidden"
          >
            <span className=" text-white font-sm">Next</span>
          </button>
        </div>
      </div> */}
    </form>
  );
};

export default StepTwo;
