import React, { useState, useEffect } from "react";
import MobileSelect from "./MobileSelect";
import MenuItem from "@mui/material/MenuItem";
import { BathRooms, BedRooms, serverAPI } from "../../utils/envConfig";
import MobileTextField from "./MobileTextField";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import { useSelector } from "react-redux";
import axios from "axios";
import Loader from "../Loader";
const CssTextField = styled(TextField)({
  "& .MuiInput-underline:after": {
    borderBottomColor: "#CE3737",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#6b7280",
    },
    "&:hover fieldset": {
      borderColor: "#6b7280",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#CE3737",
    },
  },
  "& .MuiInputBase-input": {
    "& fieldset": {
      borderColor: "#D1D5DB",
    },
    "&:hover fieldset": {
      borderColor: "#6b7280",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#CE3737",
    },
  },
});
const headers = {
  "Content-Type": "application/json",
};
const StepThree = ({ nextStep, handleFormData, values, setFormData }) => {
  const community = useSelector((state) => state.property.communities);
  const [customOptions, setCustomOptions] = useState([]);
  const [buildingOptions, setBuildingOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const propType = useSelector((state) => state.property.propertyTypes);
  useEffect(() => {
    let options = [];
    community.length > 0 &&
      community.forEach((ele) =>
        options.push({ label: ele.community, value: ele.id })
      );
    setCustomOptions(options);
  }, [community]);
  useEffect(() => {
    async function fetchBuildings() {
      try {
        setLoading(true);
        let res = await axios.get(`${serverAPI}/buildings`, { headers });
        let options = [];
        res.data.length > 0 &&
          res.data.forEach((ele) =>
            options.push({ label: ele.name, value: ele.id })
          );
        setBuildingOptions(options);
        setLoading(false);
      } catch (err) {
        // console.log(err)
      }
    }
    fetchBuildings();
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    nextStep();
  };
  return (
    <div className="grid grid-cols-1 justify-center items-center">
      <p className="text-2xl font-bold text-center py-3 text-navy font-Lexend">Property Details</p>
      {loading ? (
        <Loader />
      ) : (
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="lg:gap-y-8  gap-x-8 p-4 h-full justify-center items-center">
            <div className="relative flex flex-col gap-y-4  justify-center items-center h-full text-xl  w-full ">
            
                <MobileSelect
                  label="Beds"
                  onChange={handleFormData("beds")}
                  value={values.beds}
                >
                  {BedRooms.length > 0 &&
                    BedRooms.map((bed) => (
                      <MenuItem key={bed} value={bed}>
                        {bed}
                      </MenuItem>
                    ))}
                </MobileSelect>
                <MobileSelect
                  label="Baths"
                  onChange={handleFormData("baths")}
                  value={values.baths}
                >
                  {BathRooms.length > 0 &&
                    BathRooms.map((bath) => (
                      <MenuItem key={bath} value={bath}>
                        {bath}
                      </MenuItem>
                    ))}
                </MobileSelect>
          
              <MobileSelect
                label="Property Type"
                onChange={handleFormData("propertyType")}
                value={values.propertyType}
              >
                {propType &&
                  propType.length > 0 &&
                  propType.map((a) => (
                    <MenuItem key={a.id} value={a.id}>
                      {a.propertyType}
                    </MenuItem>
                  ))}
              </MobileSelect>
              <MobileTextField
                required={false}
                type="number"
                label="Size"
                value={values.size}
                  onChange={ handleFormData( "size" ) }
                  
                />
                <MobileTextField
                  label="Asking Price"
                  required={ true }
                  value={ values.askingPrice }
                  type="number"

                  onChange={ handleFormData( "askingPrice" ) }
                />

                { buildingOptions && buildingOptions.length > 0 && (
                  <Autocomplete
                    sx={ { width: "100%" } }
                    id="multiple-limit-tags"
                    options={ buildingOptions }
                    getOptionLabel={ ( option ) => option.label }
                    filterSelectedOptions
                    value={ values.building }
                    onChange={ ( event, newInput ) =>
                    {
                      if ( newInput !== null )
                      {
                        setFormData( ( prevState ) => ( {
                          ...prevState,
                          building: newInput,
                        } ) );
                      }
                    } }
                    renderInput={ ( params ) => (
                      <CssTextField
                        { ...params }
                        label={ null }
                        sx={ {
                          border: "black",
                        } }
                        placeholder="Building"
                        className="mb-[1rem] block w-full shadow-sm focus:ring-red-500 focus:border-red-500  sm:text-sm border-gray-100 rounded-md"
                      />
                    ) }
                  />
                ) }
                { customOptions && customOptions.length > 0 && (
                  <Autocomplete
                    sx={ { width: "100%" } }
                    id="multiple-limit-tags"
                    options={ customOptions }
                    getOptionLabel={ ( option ) => option.label }
                    filterSelectedOptions
                    value={ values.community }
                    onChange={ ( event, newInput ) =>
                    {
                      if ( newInput !== null )
                      {
                        setFormData( ( prevState ) => ( {
                          ...prevState,
                          community: newInput,
                        } ) );
                      }
                    } }
                    renderInput={ ( params ) => (
                      <CssTextField
                        { ...params }
                        label={ null }
                        sx={ {
                          border: "black",
                        } }
                        inputProps={ {
                          ...params.inputProps,
                          required:
                            !values.community ||
                            ( values.community && values.community.length === 0 ),
                        } }
                        placeholder="Community"
                        className="input-text mb-[1rem] block w-full shadow-sm focus:ring-red-500 focus:border-red-500  sm:text-sm border-gray-100 rounded-md"
                      />
                    ) }
                  />
                ) }
            </div>
              
        
          </div>
          <div className="grid grid-cols-2 gap-x-0 items-start  px-4 mb-4">
            <div className="mt-1 col-span-2 justify-self-center">
              <button
                type="submit"
                id="kt_sign_in_submit"
                  className="px-16 py-2.5 text-xl bg-night rounded-full  my-3 
                  text-[#FFDEAB]  hover:bg-[#E3EFFA] hover:text-night  border border-night  relative block  overflow-hidden"
              >
                  <span className=" font-sm font-Lexend">Next</span>
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default StepThree;
