import React, { useState, useEffect } from "react";
import Rent from "./Rent";
import Sale from "./Sale";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const StepOne = ({ nextStep, handleFormData, values, setFormData,page=false }) => {
  const handleChoice = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      rentOrSale: val,
    }));
    nextStep();
  };
  return (
    <form>
      <div className="grid grid-cols-1 justify-center items-center ">
        <div className={classNames(page?"md:grid-cols-1":"md:grid-cols-2","grid grid-cols-1 gap-y-4 gap-x-8 p-4 h-full justify-center items-center")} >
      {page?
      <>
      <Sale handleChoice={handleChoice}/>
      <Rent handleChoice={handleChoice}/>
      </>
      :
      <>
      <Rent handleChoice={handleChoice}/>
      <Sale handleChoice={handleChoice}/>
      </>
      }
        </div>
      </div>
    </form>
  );
};

export default StepOne;
