import React from "react";

export default function InputText({
    inputProps = {
        className: "",
        id: "inputText",
    },
    label,
    className,
    labelClassNames,
}) {
    return (
        <>
            <div className={`relative w-full ${className}`}>
                <input
                    type="text"
                    id={inputProps.id}
                    placeholder="Enter text"
                    {...inputProps}
                    className={`peer m-0 block w-full rounded-full border border-solid border-neutral-400 bg-white bg-clip-padding py-4 px-3 text-base font-normal leading-tight text-neutral-700 ease-in-out placeholder:text-transparent focus:border-main focus:bg-white focus:pt-[1.625rem] focus:pb-[0.625rem] focus:text-neutral-700 focus:shadow-te-primary focus:outline-none focus:ring-main dark:bg-neutral-800 dark:text-neutral-200 [&:not(:placeholder-shown)]:pt-[1.625rem] [&:not(:placeholder-shown)]:pb-[0.625rem] ${inputProps.className}`}
                />
                <label
                    htmlFor={inputProps.id}
                    className={`pointer-events-none absolute top-0 left-0 origin-[0_0] border border-solid border-transparent py-4 px-3 text-neutral-400 transition-[opacity,_transform] duration-100 ease-in-out peer-focus:translate-x-[0.15rem] peer-focus:-translate-y-2 peer-focus:scale-[0.85] peer-focus:opacity-[0.65] peer-[:not(:placeholder-shown)]:translate-x-[0.15rem] peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:scale-[0.85] peer-[:not(:placeholder-shown)]:opacity-[0.65] motion-reduce:transition-none dark:text-neutral-200 ${labelClassNames}`}
                >
                    {label}
                </label>
            </div>
        </>
    );
}
