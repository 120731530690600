import React, { useState, useEffect } from "react";
import MobileTextField from "./MobileTextField";
import { hasCookie, getCookie } from "cookies-next";
import { formName, buttonName } from "../../utils/envConfig";
import InputContact from "../Inputs/InputContact";
import InputText from "../Inputs/InputText";
import InputEmail from "../Inputs/InputEmail";
import AgreeToTerms from "../AgreeToTerms";

const StepFour = ({
    nextStep,
    handleFormData,
    values,
    setFormData,
    handleSubmit,
    handleErrors = (name, err) => {},
    handleSelectCountry = (country) => {},
    selectedCountry = {},
    errors = {},
    agreed = true,
    handleAgree = (e) => {},
    errorMessage,
}) => {
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        if (hasCookie("authuser")) {
            setLoggedIn(true);
            const { first_name, last_name, phone, email } = JSON.parse(
                getCookie("authuser")
            );

            setFormData((prevState) => ({
                ...prevState,
                name: `${first_name} ${last_name}`,
                email: email,
                phone: phone,
            }));
        }
    }, []);

    const handleLocalSubmit = (e) => {
        // console.log(e);
        e.preventDefault();
        handleSubmit();
    };

    return (
        <form
            id={formName.newListing + " " + values.rentOrSale}
            onSubmit={(e) => handleLocalSubmit(e)}
        >
            <div className="grid grid-cols-1 justify-center items-center">
                <div className="flex flex-col justify-center itemscenter gap-y-4 p-4">
                    <p className="text-2xl font-bold text-center">
                        Your Information
                    </p>
                    <div>
                        <InputText
                            label={"Name *"}
                            inputProps={{
                                required: true,
                                value: values?.name,
                                onChange: handleFormData("name"),
                                onFocus: (e) => {
                                    handleErrors("name", "");
                                },
                                onBlur: (e) => {
                                    const value = e?.target?.value?.trim();
                                    if (!value?.length) {
                                        handleErrors(
                                            "name",
                                            "Name is required"
                                        );
                                        return;
                                    }

                                    if (value?.length < 2) {
                                        handleErrors("name", "Name too short");
                                        return;
                                    }
                                },
                                minLength: 2,
                                autoComplete: "name",
                            }}
                        />
                        {errors?.name && (
                            <div className="text-sm text-red-500">
                                {errors?.name}
                            </div>
                        )}
                    </div>
                    {/* <MobileTextField
                        label="Name"
                        required={true}
                        value={values.name}
                        // disabled={loggedIn}
                        onChange={handleFormData("name")}
                    /> */}
                    <div>
                        <InputEmail
                            containerClassNames="relative"
                            inputProps={{
                                className:
                                    "peer m-0 block w-full rounded-full border border-solid !border-neutral-400 bg-white bg-clip-padding !py-4 px-3 text-base font-normal leading-tight text-neutral-700 ease-in-out placeholder:text-transparent focus:!border-main focus:!bg-white focus:pt-[1.625rem] focus:pb-[0.625rem] focus:text-neutral-700 focus:shadow-te-primary focus:!outline-none focus:!ring-main dark:bg-neutral-800 dark:text-neutral-200 [&:not(:placeholder-shown)]:pt-[1.625rem] [&:not(:placeholder-shown)]:pb-[0.625rem]",
                                placeholder: "example@email.ext",
                                required: true,
                                id: "email",
                                onChange: handleFormData("email"),
                                type: "email",
                                autoComplete: "email",
                            }}
                            onError={(err) => {
                                handleErrors("email", err);
                            }}
                        >
                            <label
                                htmlFor="email"
                                className="text-gray-700 pointer-events-none absolute top-0 left-0 origin-[0_0] border border-solid border-transparent py-4 px-3 text-neutral-400 transition-[opacity,_transform] duration-100 ease-in-out peer-focus:translate-x-[0.15rem] peer-focus:-translate-y-2 peer-focus:scale-[0.85] peer-focus:opacity-[0.65] peer-[:not(:placeholder-shown)]:translate-x-[0.15rem] peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:scale-[0.85] peer-[:not(:placeholder-shown)]:opacity-[0.65] motion-reduce:transition-none dark:text-neutral-200"
                            >
                                email@example.com *
                            </label>
                        </InputEmail>
                        {errors?.email && (
                            <div className="text-sm text-red-500">
                                {errors?.email}
                            </div>
                        )}
                    </div>
                    {/* <MobileTextField
                        required={true}
                        label="Email"
                        value={values.email}
                        // disabled={loggedIn}
                        onChange={handleFormData("email")}
                    /> */}
                    <div>
                        <InputContact
                            containerClasses={
                                "!bg-white !border-neutral-400 !shadow-none focus:!border-1"
                            }
                            inputProps={{
                                className:
                                    "!py-3 !border-l-neutral-400 placeholder:text-neutral-400",
                                onChange: handleFormData("phone"),
                                value: values?.phone,
                                placeholder: "Phone",
                                autoComplete: "tel",
                            }}
                            onSelectCountry={handleSelectCountry}
                            country={selectedCountry}
                            onError={(err) => handleErrors("phone", err)}
                        />
                        {errors?.phone && (
                            <div className="text-sm text-red-500">
                                {errors?.phone}
                            </div>
                        )}
                    </div>
              
                    <AgreeToTerms agreed={agreed} onClick={handleAgree} />

                    {errorMessage && <div>{errorMessage}</div>}
                    <div className="grid grid-cols-2 gap-x-0 items-start  px-4 mb-4">
                        <div className="mt-1 col-span-2 justify-self-center">
                            <button
                                id={buttonName.submit}
                                type="submit"
                                className="px-10 py-2.5 text-xl bg-night rounded-full text-[#FFDEAB] my-3
                                 hover:bg-[#E3EFFA]  hover:text-night border border-night relative block  overflow-hidden"
                            >
                                <span className="font-lexend font-sm">
                                    Submit
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default StepFour;
