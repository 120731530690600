export default function Sale({handleChoice}) {
    return (
      <div className="relative flex flex-col gap-y-2 pt-4 justify-center items-center h-full  text-center text-xl overflow-hidden w-full ">
      <p className="font-bold text-xl">For Sale</p>
      <button
        onClick={() => handleChoice("Sell")}
          className="lg:text-xl text-sm flex bg-new-listing justify-center items-center rounded-md text-white font-bold cursor-pointer  px-4 py-2"
      >
        <p className="truncate">Create listing</p>
      </button>
      <div className="">
        <img src="/img/sale.jpg" className="w-full h-40" />
      </div>
    </div>
    );
}
