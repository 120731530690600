import React, { useEffect, useState } from "react";
import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { getCookie, hasCookie } from "cookies-next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Form from "./NewListingForm/Form"

const NewListingModal = ({
  showNewListing,
  setShowNewListing,
  loading = false,
}) => {
  const cancelButtonRef = useRef(null);
  const propertyObj = {
    propertyMultiple: false,
    rentOrSale: "",
    propertyType: "",
    size: "",
    beds: "",
    baths: "",
    askingPrice: "",
    name: "",
    phone: "",
    email: "",
    building: null,
    community: null,
  };

  return (
    <>

      <>
        {showNewListing ? (
          <div className="opacity-70 fixed inset-0 z-50 backdrop-blur-[42px] bg-white/40 "></div>
        ) : null}
        <Transition.Root show={showNewListing} as={Fragment}>
          <Dialog
          // onMouseDownCapture={(e) => e.preventDefault()}
            as="div"
            className="fixed z-50 inset-0 overflow-y-auto"
            initialFocus={cancelButtonRef}
            onClose={(e) => setShowNewListing(false)}
          >
            <div className="flex items-end justify-center pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-night bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="transform transition duration-[400ms]"
                enterFrom="opacity-0  scale-50"
                enterTo="opacity-100 rotate-0 scale-100"
                leave="transform duration-200 transition ease-in-out"
                leaveFrom="opacity-100 rotate-0 scale-100 "
                leaveTo="opacity-0 scale-50 "
              >
                <div className="relative inline-block mx-auto bg-white rounded-lg px-4 pt-5 pb-4 text-left
                 overflow-hidden transform transition-all sm:my-8 align-middle   sm:p-6  md:w-[90vw] xl:w-[60vw] 3xl:max-w-[1000px] h-auto w-[90vw] md:h-auto">
                  <div className="z-20 absolute top-0 right-0 pt-4 pr-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                      onClick={() => setShowNewListing(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <Form
                    property={propertyObj}
                    setShowNewListing={setShowNewListing}

                  />
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

      </>

    </>
  );
};

export default NewListingModal;
