import { useState, useEffect } from "react";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import { Designs } from "./Steps";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import Loader from "../Loader";
import { hasCookie, getCookie } from "cookies-next";
import { toast } from "react-toastify";
import axios from "axios";
import {
    defaultCountry,
    serverAPI,
    validateEmail,
    validatePhoneNumbers,
} from "../../utils/envConfig";
import { useRouter } from "next/router";

function Form({
    property,
    setShowNewListing,
    design = Designs.Circles,
    page = false,
}) {
    const router = useRouter();
    // State Variables
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({ ...property });
    const [propId, setPropId] = useState(null);
    const [loading, setLoading] = useState(false);

    const [selectedCountry, setSelectedCountry] = useState(defaultCountry);
    const [errors, setErrors] = useState({});

    const [isAgreed, setIsAgreed] = useState(true);

    const [errorMessage, setErrorMessage] = useState("");

    const nextStep = () => {
        // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setStep(step + 1);

        let formObject = {
            mystep: step + 1,
            myformData: formData,
            mypropId: propId,
        };
        localStorage.setItem("propertyForm", JSON.stringify(formObject));
    };
    const lastStep = () => {
        // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setStep(2);

        let formObject = {
            mystep: 2,
            myformData: formData,
            mypropId: propId,
        };
        localStorage.setItem("propertyForm", JSON.stringify(formObject));
    };

    useEffect(() => {
        // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        if (JSON.parse(localStorage.getItem("propertyForm"))) {
            const { myformData, mypropId } = JSON.parse(
                localStorage.getItem("propertyForm")
            );
        }
    }, []);

    // function for going to previous step by decreasing step state by 1
    const prevStep = (e) => {
        // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setStep(step - 1);
    };
    const handlePropId = (id) => {
        setPropId(id);
    };
    const handleStep = (s) => {
        setStep(s);
    };

    // handling form input data by taking onchange value and updating our previous form data state
    const handleInputData = (input) => (e) => {
        // input value from the form
        const { value } = e.target;

        //updating for data state taking previous state and then adding new value to create new object
        setFormData((prevState) => ({
            ...prevState,
            [input]: value,
        }));
    };

    const handleErrors = (name, err) => {
        setErrors((prev) => ({
            ...prev,
            [name]: err,
        }));
    };

    const handleSubmit = async () => {
        let isError = false;

        if (!isAgreed) {
            isError = true;
            alert("Please agree to terms and conditions");
        }

        const name = formData?.name?.trim();

        if (errors?.name || !name || name?.length < 2) {
            isError = true;
            handleErrors(
                "name",
                errors?.name ?? !name ? "Name is required" : "Name too short"
            );
        }

        const isValidEmail = validateEmail(formData?.email);

        if (errors?.email ?? !isValidEmail) {
            isError = true;
            handleErrors("email", errors?.email ?? "Valid email required");
        }

        const isValidContact = validatePhoneNumbers({
            cca2: selectedCountry?.cca2,
            code: selectedCountry?.code,
            phone: formData?.phone,
        });

        const phone = formData?.phone?.trim();

        if (phone?.length) {
            if (!isValidContact) {
                isError = true;
                handleErrors("phone", "Invalid number for selected country");
            }
        }

        if (isError) {
            return;
        }

        const payload = JSON.stringify({
            full_name: formData.name,
            phone: `${selectedCountry?.code}${formData.phone}`,
            email: formData.email,
            type: formData.rentOrSale,
            multiple: formData.propertyMultiple,
            property_type_id:
                formData.propertyType !== ""
                    ? formData.propertyType
                    : undefined,
            community_id: formData.community
                ? formData.community.value
                : undefined,
            building_id: formData.building
                ? formData.building.value
                : undefined,
            beds: formData.baths !== "" ? formData.beds : undefined,
            baths: formData.baths !== "" ? formData.baths : undefined,
            price:
                formData.askingPrice !== "" ? formData.askingPrice : undefined,
            size: formData.size !== "" ? formData.size : undefined,
            cca2: selectedCountry?.cca2,
        });
        const headers = {
            "Content-Type": "application/json",
        };
        if (hasCookie("authuser")) {
            const { access_token } = JSON.parse(getCookie("authuser")) || "";
            headers["Authorization"] = `Bearer ${access_token}`;
        }
        setLoading(true);
        try {
            console.log(payload);
            const resp = await axios.post(
                `${serverAPI}/propList/request`,
                payload,
                {
                    headers,
                }
            );
       
            window.location.href = '/thank-you';
        } catch (e) {
            console.log(e);
            setErrorMessage(e?.response?.data?.message);
        } finally {
            setLoading(false);
            // setShowNewListing(false);
        }
    };

    if (loading) {
        return (
            <div className="p-6">
                <Loader />
            </div>
        );
    }

    // javascript switch case to show different form in each step
    switch (step) {
        // case 1 to show stepOne form and passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
        case 1:
            return (
                <>
                    <div className="relative">
                        {/* <Steps
                            current={step}
                            handleStep={handleStep}
                            prevStep={prevStep}
                            design={design}
                        /> */}
                        <StepOne
                            nextStep={nextStep}
                            prevStep={prevStep}
                            handleFormData={handleInputData}
                            setFormData={setFormData}
                            values={formData}
                            page={page}
                        />
                    </div>
                </>
            );
        // case 2 to show stepTwo form passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
        case 2:
            return (
                <>
                    {/* <Steps
                        current={step}
                        handleStep={handleStep}
                        prevStep={prevStep}
                        design={design}
                    /> */}
                    <StepTwo
                        nextStep={nextStep}
                        handleStep={handleStep}
                        handleFormData={handleInputData}
                        values={formData}
                        setFormData={setFormData}
                        page={page}
                    />
                </>
            );
        case 3:
            return (
                <>
                    <StepThree
                        nextStep={nextStep}
                        prevStep={prevStep}
                        handleFormData={handleInputData}
                        setFormData={setFormData}
                        values={formData}
                    />
                </>
            );
        case 4:
            return (
                <>
                    <StepFour
                        nextStep={nextStep}
                        prevStep={prevStep}
                        handleFormData={handleInputData}
                        setFormData={setFormData}
                        values={formData}
                        handleSubmit={handleSubmit}
                        handleErrors={handleErrors}
                        handleSelectCountry={(country) =>
                            setSelectedCountry(country)
                        }
                        selectedCountry={selectedCountry}
                        errors={errors}
                        agreed={isAgreed}
                        handleAgree={(e) => setIsAgreed((prev) => !prev)}
                        errorMessage={errorMessage}
                    />
                </>
            );

        // default case to show nothing
        default:
            return <></>;
    }
}

export default Form;
