export default function Rent({handleChoice}) {
    return (
        <div className="relative flex flex-col gap-y-2 pt-4 justify-center items-center h-full  text-center text-xl overflow-hidden w-full ">
        <p className="font-bold text-xl">For Rent</p>
        <button
          onClick={() => handleChoice("Rent")}
          className="lg:text-xl text-sm flex bg-[#ffac2e] justify-center items-center  text-white font-bold cursor-pointer shadow-2xl rounded-md px-4 py-2"
        >
          <p className="truncate">Create listing</p>
        </button>
        <div className="">
          <img src="/img/rent.jpg" className="w-full h-40" />
        </div>
      </div>
    );
}
